import React from "react";
import VideoSlider from "../../Components/Slider/VideoSlider";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";

const PracticePolicy = () => {
  const VideoData = {
    sectionHeadingData: {
      title: `Informed Consent Videos   `,
    },
    services: [
      {
        title: "Tooth Cleaning with Pav Dental",
        videoSrc:
          "https://res.cloudinary.com/db1i46uiv/video/upload/v1725865458/y2mate.com_-_Empower_Your_Smile_Understanding_Teeth_Whitening_Consent_with_Pav_Dental_360P_jp1vnq.mp4",
      },
    ],
  };

  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Pav Dental Documents" />

      <div className="container mb-5">
        <h3>- Pav Dental Practice Policy</h3>
        <VideoSlider data={VideoData} varient="st-type1" />

        <h3>- Pav Dental Post Operative Instructions</h3>
        <br />

        <h5>POIG - Root Canal</h5>
        <br />
        <h5>POIG - Tooth Extraction</h5>
        <br />
        <h5>POIG - Whitening</h5>
        <br />
      </div>
    </>
  );
};

export default PracticePolicy;
