import React from 'react';
import SectionHeading from '../../Components/SectionHeading/SectionHeading';

const ComplaintsPolicy = () => {
  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Complaints Policy" />
      <div className="st-height-b80 st-height-lg-b80" />
      <div className="container">
        <h4>Complaints Policy for Pav Dental</h4>
        <p><strong>Purpose:</strong> To ensure that any complaints or concerns about the services provided by Pav Dental Practice are addressed promptly, professionally, and transparently, and to use feedback as an opportunity to improve the quality of care and service.</p>
        
        <p><strong>Scope:</strong> This policy applies to all employees, associates, contractors, patients, and any individual in interaction with Pav Dental Practice.</p>
        
        <p><strong>Policy Statement:</strong> Pav Dental Practice values the feedback of its patients and is committed to ensuring that any concerns or complaints are taken seriously, handled effectively, and resolved to the satisfaction of all parties involved.</p>

        <p>At Pav Dental, we take complaints seriously to ensure that our service meets expectations. All complaints are dealt with courteously and promptly to resolve the matter as quickly as possible. Our aim is to react to complaints and learn from every mistake that we make. We respond to any concerns in a caring and sensitive way.</p>
        
        <h4>Complaints Handling</h4>
        <p>The person responsible for dealing with any complaints about the service that we provide is the Complaints Manager. If we receive a complaint by telephone or in person, we will listen and offer to refer the individual to the Complaints Manager immediately. If the Complaints Manager is not available at the time, we will arrange a convenient time for the Complaints Manager to contact the individual. The member of staff will take brief details of the issue, pass it to the Complaints Manager, and provide the individual with a copy. If the matter requires a more immediate response, we will arrange for a senior member of the dental team to deal with it.</p>
        
        <p>If we receive a complaint in writing or by email, it will be passed immediately to the Complaints Manager.</p>
        
        <p>If a complaint is about any aspect of clinical care or associated charges, it will usually be referred to the dentist concerned, unless the individual does not want this to happen.</p>
        
        <h4>Acknowledgement and Investigation</h4>
        <p>We will acknowledge a complaint in writing and enclose a copy of this code of practice as soon as possible, normally within 3 working days. We will offer to discuss the complaint with the individual, and confirm how they would like to be kept informed of developments – by telephone, letters, email, or face-to-face meetings. We will inform the individual about how the complaint will be handled and the likely time that the investigation will take to complete. If the individual does not wish to discuss the complaint further, we will still inform them of the expected timescale for completing the investigation.</p>
        
        <p>We will seek to investigate the complaint within six months and, as far as reasonably practicable, keep the individual informed as to the progress of the investigation.</p>
        
        <h4>Resolution and Records</h4>
        <p>When we have completed our investigation, we will provide the individual with a full written report, which will include an explanation of how we considered the complaint, the conclusions reached in respect of each specific part of the complaint, details of any necessary remedial action taken, and whether further action will be taken.</p>
        
        <p>Proper and comprehensive records will be kept of any complaints received and the action we take. These records will be reviewed regularly to ensure that we take every opportunity to improve our service.</p>
        
        <h4>Escalation</h4>
        <p>If the individual making the complaint is not satisfied with the result of our investigation, we will advise them to refer the complaint to:</p>
        <ul>
          <li><strong>For complaints about NHS treatment:</strong> NHS England, PO Box 16738, Redditch B97 9PT (email: England.contactus@nhs.net); or The Parliamentary and Health Service Ombudsman, Millbank Tower, Millbank London SW1P 4QP (0345 015 4033 or www.ombudsman.org.uk).</li>
          <li><strong>For complaints about private treatment:</strong> Dental Complaints Service, Stephenson House, 2 Cherry Orchard Road, Croydon CR0 6BA (08456 120 540); General Dental Council, 37 Wimpole Street, London, W1M 8DQ.</li>
        </ul>
        
        <p><strong>Policy written by:</strong> Dr Pavan Amar Singh Bhogal GDC: 273704 April 2023. Updated Yearly.</p>
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </>
  );
};

export default ComplaintsPolicy;
